.App {
  font-family: sans-serif;
  text-align: center;
}

[data-amplify-authenticator] {
  --amplify-components-button-border-radius: 16px;
  --amplify-components-button-primary-color: var(--white);
  --amplify-components-button-primary-background-color: var(--pt-purple);
  --amplify-components-button-primary-active-background-color: var(--pt-purple);
  --amplify-components-button-primary-focus-background-color: var(--pt-purple-opaque-56);
  --amplify-components-button-primary-focus-border-color: var(--pt-purple);
  --amplify-components-button-primary-hover-background-color: var(--pt-purple-opaque-56);
  --amplify-components-fieldcontrol-border-radius: 16px;
  --amplify-components-fieldcontrol-border-color: var(--grey);
  --amplify-components-fieldcontrol-border-width: 1px;
  --amplify-components-fieldcontrol-focus-border-color: var(--pt-purple);
  --amplify-components-button-link-color: var(--pt-purple);
  --amplify-components-button-link-hover-background-color: var(--pt-purple-opaque-8);
  --amplify-components-button-link-hover-color: var(--pt-purple);
  --amplify-components-button-link-focus-background-color: var(--pt-purple-opaque-8);
  --amplify-components-button-link-focus-color: var(--pt-purple);
  --amplify-components-button-link-focus-border-color: var(--pt-purple);
  --amplify-components-button-link-active-color: var(--pt-purple);
  --amplify-components-button-link-active-background-color: var(--pt-purple-opaque-8);
  --amplify-components-button-hover-background-color: var(--pt-purple-opaque-8);
  --amplify-components-button-hover-border-color: var(--pt-purple-opaque-56);
  --amplify-components-button-active-background-color: var(--pt-purple-opaque-56);
  --amplify-components-button-focus-border-color: var(--pt-purple);
  --amplify-components-authenticator-modal-background-color: var(--grey-3);
  --amplify-components-authenticator-router-border-color: transparent;


  --amplify-radii-small: 0;
  --amplify-radii-medium: 0;
  --amplify-radii-large: 0;
  --amplify-space-small: 1rem;
  --amplify-space-medium: 1.5rem;
  --amplify-space-large: 2rem;
  --amplify-border-widths-small: 2px;
  --amplify-border-widths-medium: 4px;
  --amplify-border-widths-large: 8px;
}

[data-amplify-authenticator] [data-amplify-router] {
  border-radius: 16px;
}

.amplify-tabs {
  display: none;
}
