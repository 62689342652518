.App {
  text-align: center;
}

#root {
  display: block;
  height: 100%;
  width: 100%;
  overflow: auto;
}
